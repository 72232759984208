import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import bgimg from "../../images/2260.jpg"
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(Header)`
  ${tw`pt-2 max-w-none `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-secondary-500 hover:text-gray-100`}
  }
  ${NavToggle}.closed {
    ${tw`text-secondary-500 hover:text-gray-100`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url(${bgimg});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-50`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-32 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-secondary-500 border-l-4 border-secondary-500 font-semibold text-base`;

const PrimaryAction = tw.a`px-8 text-sm sm:text-base sm:mt-2 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/#features">
        Sobre
      </NavLink>
      <NavLink href="/#precos">
        Preços
      </NavLink>
      <NavLink href="/#faq">
        FAQ
      </NavLink>
      <NavLink href="/#contato">
        Contato
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/#contato">
        Contrate agora
      </PrimaryLink>
    </NavLinks>,
    <NavLinks>
     
   </NavLinks>
  ];

  return (
    <Container>
      
      <OpacityOverlay />
      <HeroContainer>
        
        <TwoColumn>
          <LeftColumn>
            <Notification>Receba instantaneamente sem taxas sobre a venda</Notification>
            <Heading>
              <SlantedBackground>Sua cantina</SlantedBackground>
              <br />
              <SlantedBackground>livre de moedas</SlantedBackground>
            </Heading>
            <br /><br />
            <PrimaryAction href="/#features">Saiba mais</PrimaryAction>
          </LeftColumn>
          <RightColumn>
            <StyledResponsiveVideoEmbed
              url="https://player.vimeo.com/video/745029822?title=0&amp;byline=0&amp;h=d15d179915&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              background="transparent"
            />
          </RightColumn>
        </TwoColumn>
        <StyledHeader links={navLinks} />
      </HeroContainer>
      
    </Container>
  );
};
