import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg"
import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg"
import ContactUs from "components/forms/TwoColContactUsWithIllustration.js"


export default () => {
  return (
    <AnimationRevealPage disabled>
      <Hero />
      <section id="features" name="features" />
      <Features />
      <section id="precos" name="precos" />
      <Pricing />                 
      <section id="faq" name="faq" />
      <FAQ />
      <section id="contato" name="contato" />
      <ContactUs />
      <Footer />
    </AnimationRevealPage>
  );
}
